const getHeaders = () => ({
  // "Content-Type": "application/json; charset=utf-8",
  'Content-Type': 'application/json',
})

const getUrl = endpoint => {
  let baseApiUrl = process.env.GATSBY_BASE_API_URL || 'https://localhost:3000'
  if (baseApiUrl.endsWith('/')) {
    baseApiUrl = `${baseApiUrl.slice(0, baseApiUrl.length - 1)}`
  }
  endpoint = endpoint.startsWith('/') ? endpoint.slice(1) : endpoint
  return `${baseApiUrl}/v3/${endpoint}`
}

export const get = endpoint => {
  const url = getUrl(endpoint)
  return (
    fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: getHeaders(),
    })
      // A fetch() promise will reject with a TypeError when a network error is encountered
      // or CORS is misconfigured on the server side
      .catch(error =>
        Promise.reject({
          code: 9999,
          error_message: error.toString(),
        })
      )
      .then(r => r.json())
      .then(r => parseResponseStatus(r))
  )
}

export const post = (endpoint, data) => {
  const body = JSON.stringify(data)
  const url = getUrl(endpoint)
  return (
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: getHeaders(),
      body,
    })
      // A fetch() promise will reject with a TypeError when a network error is encountered
      // or CORS is misconfigured on the server side
      .catch(error =>
        Promise.reject({
          code: 9999,
          error_message: error.toString(),
        })
      )
      .then(r => r.json())
      .then(r => parseResponseStatus(r))
  )
}

export const parseResponseStatus = res => {
  // If it's an Iris Response
  if (res.meta) {
    // If the code is anything other than 0
    if (res.meta.code) {
      return Promise.reject(res.meta)
    }
    // If the code is 0, and the res is successful
    return res.data
  }
  // If it's an uncategorized error
  return Promise.reject('Fatal Server Error')
}
