import React from 'react'

import './styles.scss'

import windowsIcon from './windows.svg'
import { Layout, requirementsCopy } from '../Shared'

import ExperienceGuarantee from './guarantee'
import { getSignupUrl, getAccountUrl } from '../../utils/url'
import Emoji from '../Shared/Emoji'
import { IrisCTALinkButton, colors } from '../Shared/IrisCTALinkButton'
import { navigate } from 'gatsby'
import queryString from 'query-string'
import { get } from '../../utils/request'

class Welcome extends React.Component {
  componentDidMount() {
    const { location } = this.props;
    const { search } = location;

    if (search) {
      // If there are URL query params, re-navigate to the page with state
      const queryParams = queryString.parse(search);
      if (queryParams.confirmation_token) {
        const token = queryParams.confirmation_token;

        get(`users/email-confirmation/${token}`)
          .then(() => {
            // Wait for window.analytics to be defined
            this.waitForAnalyticsAndTrack();
          })
          .catch(err => console.log(`Server error: ${err}`));
      }
    }
  }

  waitForAnalyticsAndTrack() {
    if (window.analytics && typeof window.analytics.track === 'function') {
      window.analytics.track('signup_email_verified');
    } else {
      setTimeout(() => this.waitForAnalyticsAndTrack(), 100);
    }
  }

  render() {
    return (
      <Layout>
        <div>
          <div className="main-content">
            <ChampionHeader />
          </div>
          <TrialDetails />
          <div className="main-content">
            <ExperienceGuarantee />
          </div>
        </div>
      </Layout>
    )
  }
}

export default Welcome

const ChampionHeader = ({ downloadProspectURL }) => (
  <div
    style={{ marginTop: '10em', paddingBottom: '4.0em', textAlign: 'center' }}
  >
    <div style={{ marginTop: '2.0em' }}>
      <span className="welcome-header">
        Welcome <Emoji label="celebrate" symbol={`\u{1F389}`}></Emoji>
      </span>
      <div>
        <p
          style={{
            marginBottom: '1.0em',
            fontSize: '18px',
            fontWeight: 'normal',
          }}
        >
          Please download Prospect <br className={'manual-breaks'} />
          to start your trial
        </p>
        <div>
          <IrisCTALinkButton
            href={downloadProspectURL}
            cta={'Download Prospect'}
            customStyles={{
              maxWidth: 365,
              height: 52,
              borderRadius: 3,
            }}
          />
        </div>
        <div
          style={{ color: '#5C5C5C', marginTop: '2.0em', fontStyle: 'italic' }}
        >
          Runs on Windows PCs <WindowsLogo />
        </div>
      </div>
    </div>
  </div>
)
ChampionHeader.defaultProps = {
  downloadProspectURL: 'https://prospect-releases.irisvr.com/ProspectSetup.exe',
}
const WindowsLogo = () => (
  <img
    style={{ verticalAlign: 'middle', marginLeft: '0.5em' }}
    src={windowsIcon}
    alt="Windows Logo"
  />
)

const TrialDetails = ({ signupFormURL, manageAccountURL }) => (
  <div className="trial-details">
    <div className="trial-cards" style={{ maxWidth: 1400, margin: 'auto' }}>
      <div class="first-card">
        <div
          style={{
            maxwidth: 435,
            margin: 'auto',
            lineHeight: '1.5em',
          }}
        >
          <p style={{ padding: 20 }} class="">
            Your <strong>trial will start</strong> when you open Prospect on PC
            after installation.
          </p>
          <p style={{ padding: 20 }}>
            Have an <strong>Meta Quest 2</strong>? More setup is needed for
            standalone devices.{' '}
            <a style={{ color: 'black' }} href={'/oculus-quest/'}>
              Please complete the form here to request access.
            </a>
          </p>
          <div style={{ textAlign: 'center', marginTop: 0, paddingRight: 20 }}>
            <IrisCTALinkButton
              href={manageAccountURL}
              cta={'Manage My Account'}
              customStyles={{
                maxWidth: 300,
                color: colors.purple,
                fontWeight: 'bold',
                textTransform: 'none',
                backgroundColor: 'transparent',
                borderRadius: 3,
              }}
            />
          </div>
        </div>
      </div>

      <div style={{ flex: 1, margin: '1.0em' }}>
        <div style={{ maxWidth: 600, margin: 'auto', lineHeight: '2.0em' }}>
          <div style={{ textAlign: 'center', marginBottom: '0.6em' }}>
            <WindowsLogo />
          </div>
          <div>
            <p style={{ marginBottom: '1.5em' }}>
              Minimum System Requirements for Prospect by IrisVR{' '}
            </p>
            <p>
              {requirementsCopy.map(({ category, copy }, key) => {
                return (
                  <RequirementBullet
                    key={key}
                    category={category}
                    copy={copy}
                  />
                )
              })}
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const RequirementBullet = ({ category, copy }) => {
  const isHeadsetCategory = category === 'Headset'
  const displayCopy = isHeadsetCategory ? copy.welcome : copy
  return (
    <div>
      <span style={{ fontWeight: 'bold' }}>
        {category}: <span style={{ fontWeight: 'normal' }}>{displayCopy}</span>
      </span>
    </div>
  )
}

TrialDetails.defaultProps = {
  signupFormURL: getSignupUrl(),
  manageAccountURL: getAccountUrl(),
}
