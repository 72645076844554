import React from 'react'

import { Helmet } from '../components/Shared'
import { helmetProps } from '../components/Welcome'

import Welcome from '../components/Welcome'

export default React.memo(props => (
  <>
    <Helmet {...helmetProps} />
    <Welcome {...props} />
  </>
))
