import React from 'react'

import hmdIcon from './hmd.svg'
import bimIcon from './bim.svg'
import simpleIcon from './simple.svg'
import starIcon from './star.svg'

const ExperienceGuarantee = () => (
  <div
    className="experience-guarantee"
    style={{ margin: '10%', marginTop: '5.0em' }}
  >
    <div style={{ marginBottom: '4.0em' }}>
      <div class="accent-border purple"></div>
      <h2>The IrisVR Guarantee</h2>
      <p>
        We build for VR first&mdash;that means we don&#8217;t compromise your
        comfort, ease of use, or performance.
      </p>
    </div>

    <div className="col1">
      <GuaranteeItem title="Comfortable" icon={hmdIcon}>
        Adheres to framerate and locomotion best practices.
      </GuaranteeItem>
      <GuaranteeItem title="Data Rich" icon={bimIcon}>
        Native support for BIM and model metadata.
      </GuaranteeItem>
      <GuaranteeItem title="Fast &amp; Simple" icon={simpleIcon}>
        Supports large files out of the box in seconds.
      </GuaranteeItem>
      <GuaranteeItem title="Premium" icon={starIcon}>
        Delivering world-class support and uptime.
      </GuaranteeItem>
    </div>
    <div style={{ clear: 'both' }}></div>
  </div>
)

const GuaranteeItem = ({ title, icon, children }) => (
  <div class="guarantee-item" style={{}}>
    <div
      style={{
        height: 40,
      }}
    >
      <img style={{ height: 38 }} src={icon} alt={title} />
    </div>
    <h3 style={{ color: '#534DC9' }}>{title}</h3>
    <p>{children}</p>
  </div>
)

export default ExperienceGuarantee
